<script>
export default {
    name: 'RecursiveJSONViewer',
    props: {
        data: [Object, Boolean, Array]
    },
    methods: {
        haveChildren(value) {
            return typeof value === 'object' && value !== null && Object.keys(value).length > 0;
        },
        formatValue(value) {
            if (value === null) return 'null';
            if (Array.isArray(value) && value.length === 0) return '[]';
            if (typeof value === 'boolean') return value.toString();
            return value;
        }
    }
};
</script>

<template>
    <div class="json__card card mb-0 pl-1">
        <h4 v-if="data === undefined">undefined</h4>
        <h4 v-else-if="typeof data === 'boolean'">{{ data }}</h4>
        <h4 v-else-if="data === true">{{ data }}</h4>
        <div v-else-if="data">
            <div v-for="(value, key) in data" :key="key">
                <div v-if="!haveChildren(value)">
                    <span class="text-uppercase text-warning font-medium-2 mb-1">
                        {{ key }}
                    </span>
                    <span class="text-uppercase font-medium-2"> : </span>
                    <span class="text-uppercase text-success font-medium-2">
                        {{ formatValue(value) }}
                    </span>
                </div>
                <div v-else>
                    <span class="text-info text-uppercase font-medium-2 d-block">
                        {{ key }} =>
                    </span>
                    <div class="ml__2">
                        <RecursiveJSONViewer :data="value" />
                    </div>
                </div>
            </div>
        </div>
        <h4 v-else>{{ $t('no_data') }}</h4>
    </div>
</template>

<style scoped lang="scss">
.ml__2 {
    margin-left: 2rem;
}
</style>
