<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';

export default {
    name: 'ViewBillingRateSettings',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: '',
                uid: '',
                is_internet: false,
                is_night_internet: false,
                is_sms: false,
                is_voice: false
            },
            userId: localStorage.getItem('userData'),
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                if (this.$store.state.me.billingTariffsUsers.includes(JSON.parse(this.userId).id)) {
                    this.pagination.current = parseInt(query.page) || 1;
                    await this.getAll();
                }
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'uid',
                    label: 'uid'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'is_internet',
                    label: this.$t('titles.is_internet')
                },
                {
                    key: 'is_night_internet',
                    label: this.$t('titles.is_night_internet')
                },
                {
                    key: 'is_sms',
                    label: this.$t('titles.is_sms')
                },
                {
                    key: 'is_voice',
                    label: this.$t('titles.is_voice')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const params = {
                page: parseInt(this.pagination.current),
                limit: parseInt(this.pagination.perPage)
            };

            try {
                const { data } = await api.billingRate.fetchAllItems(params);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.billingRate
                .fetchOneItem(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.billingRate
                .createItem(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.billingRate
                .updateItem(id, body)
                .then(({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.billingRate
                .deleteItem(id)
                .then(() => {
                    this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <div v-if="$store.getters['me/hasBillingTariffsUsers']">
            <!--    v-if="hasAccess('coin_award','view')"-->
            <page-title>
                <template #title> {{ $t('titles.billing_rates') }}</template>
                <template v-if="hasAccess('users', 'create')" #button>
                    <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                        {{ $t('button.add') }}
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </template>
            </page-title>

            <!--  TABLE  -->
            <div class="card">
                <progress-linear v-if="requestPending" />
                <b-table
                    v-else
                    :fields="fields"
                    :items="items"
                    :responsive="true"
                    borderless
                    class="news__table"
                    outlined
                    striped
                >
                    <template #cell(is_internet)="{ item }">
                        <feather-icon
                            :class="[item.is_internet ? 'text-success' : 'text-danger']"
                            :icon="item.is_internet ? 'CheckIcon' : 'XIcon'"
                            size="20"
                        />
                    </template>

                    <template #cell(is_night_internet)="{ item }">
                        <feather-icon
                            :class="[item.is_night_internet ? 'text-success' : 'text-danger']"
                            :icon="item.is_night_internet ? 'CheckIcon' : 'XIcon'"
                            size="20"
                        />
                    </template>

                    <template #cell(is_sms)="{ item }">
                        <feather-icon
                            :class="[item.is_sms ? 'text-success' : 'text-danger']"
                            :icon="item.is_sms ? 'CheckIcon' : 'XIcon'"
                            size="20"
                        />
                    </template>

                    <template #cell(is_voice)="{ item }">
                        <feather-icon
                            :class="[item.is_voice ? 'text-success' : 'text-danger']"
                            :icon="item.is_voice ? 'CheckIcon' : 'XIcon'"
                            size="20"
                        />
                    </template>

                    <template #cell(actions)="{ item, index }">
                        <div class="d-flex justify-content-end">
                            <table-action-btns
                                :delete-access="{ section: 'regions', permission: 'delete' }"
                                :delete-handler="deleteItem"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'regions', permission: 'update' }"
                                @openModal="getOneItem(item)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>

            <!--  CREATE & UPDATE MODALS  -->
            <b-modal
                id="createUpdateModal"
                v-model="isOpenModal"
                :hide-footer="isViewModal"
                :no-close-on-backdrop="true"
                :title="updatingItem ? $t('edit.billing_rate') : $t('add.billing_rate')"
                cancel-variant="danger"
                centered
                size="md"
                @hidden="onCloseModal"
            >
                <ValidationObserver ref="createUpdateModal">
                    <b-row>
                        <!--    NAME    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.title') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.title')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.name"
                                        :placeholder="$t('enter.title')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    UID    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.uid') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.uid')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.uid"
                                        :placeholder="$t('enter.uid')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                        type="number"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    IS INTERNET    -->
                        <b-col cols="6" class="d-flex mt-2 user-select-none">
                            <b-form-checkbox
                                id="is_internet"
                                v-model="dataForModal.is_internet"
                                :disabled="isViewModal"
                            />
                            <label for="is_internet" class="font-medium-1">
                                {{ $t('titles.is_internet') }}
                            </label>
                        </b-col>

                        <!--    IS INTERNET    -->
                        <b-col cols="6" class="d-flex mt-2 user-select-none">
                            <b-form-checkbox
                                id="is_night_internet"
                                v-model="dataForModal.is_night_internet"
                                :disabled="isViewModal"
                            />
                            <label for="is_night_internet" class="font-medium-1">
                                {{ $t('titles.is_night_internet') }}
                            </label>
                        </b-col>

                        <!--    IS SMS    -->
                        <b-col cols="6" class="d-flex mt-2 user-select-none">
                            <b-form-checkbox
                                id="is_sms"
                                v-model="dataForModal.is_sms"
                                :disabled="isViewModal"
                            />
                            <label for="is_sms" class="font-medium-1">
                                {{ $t('titles.is_sms') }}
                            </label>
                        </b-col>

                        <!--    IS VOICE    -->
                        <b-col cols="6" class="d-flex mt-2 user-select-none">
                            <b-form-checkbox
                                id="is_voice"
                                v-model="dataForModal.is_voice"
                                :disabled="isViewModal"
                            />
                            <label for="is_voice" class="font-medium-1">
                                {{ $t('titles.is_voice') }}
                            </label>
                        </b-col>
                    </b-row>
                </ValidationObserver>

                <template #modal-footer>
                    <b-button variant="success" @click="submitModal">
                        {{ modalSubmitBtnText }}
                    </b-button>
                </template>
            </b-modal>

            <!--  PAGINATION  -->
            <b-row>
                <b-col class="offset-3" cols="9">
                    <b-pagination
                        v-if="showPagination"
                        v-model="pagination.current"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalItem"
                        class="float-right mr-4 mb-0"
                        first-number
                        last-number
                    />
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <h3 class="text-center">{{ $t('has_not_permission') }}</h3>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
