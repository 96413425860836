<script>
import { BFormInput } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import api from '@/services/api';
import RecursiveJSONViewer from '@/components/RecursiveJSONViewer.vue';

export default {
    name: 'ViewUcellStatus',
    components: { RecursiveJSONViewer, ProgressLinear, BFormInput },
    data() {
        return {
            phone: null,
            package1: '5316',
            requestPending: false,
            response: null
        };
    },
    methods: {
        async getResponse() {
            this.requestPending = true;
            try {
                const { data } = await api.administrationUcell.fetchStatus({
                    phone: parseInt(this.phone),
                    package: parseInt(this.package1)
                });
                this.response = data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        }
    }
};
</script>

<template>
    <div class="status">
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="card p-1">
                    <h3 class="mb-1">{{ $t('filter_title') }}</h3>

                    <!--    PHONE    -->
                    <label>{{ $t('titles.phone_number') }}</label>
                    <b-form-input
                        v-model="phone"
                        :placeholder="$t('enter.phone_number')"
                        size="md"
                        type="number"
                        class="mb-1"
                    />

                    <!--                    &lt;!&ndash;    PACKAGE    &ndash;&gt;-->
                    <!--                    <label>{{ $t('titles.package') }}</label>-->
                    <!--                    <b-form-input-->
                    <!--                        v-model="package1"-->
                    <!--                        :placeholder="$t('choose.package')"-->
                    <!--                        size="md"-->
                    <!--                        type="number"-->
                    <!--                        class="mb-1"-->
                    <!--                    />-->

                    <b-button @click="getResponse" variant="success">{{
                        $t('button.filter')
                    }}</b-button>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <ProgressLinear v-if="requestPending" />
                <RecursiveJSONViewer class="p-2" v-else :data="response" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.json__content {
    & h4 {
        margin-bottom: 0;
    }
}
</style>
